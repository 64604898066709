const apiConfig = {
    /** 根据文章类型获取文章 */
    getArticleListByType: '/GetArticleByArticleType',
    /** 根据ID获取文章 */
    getArticleById: '/GetArticleById',
    /** 根据类型id 获取文章总数 */
    getArticleCountByArticleType: '/GetArticleCountByArticleType',
    /** 获取所有文章分类(导航栏) */
    getAllArticleType: '/GetArticleType',
    /** 获取所有配置项 */
    getConfigSetting: '/GetConfigSetting',
    /** 获取最新内容 */
    getNewArticle: '/GetNewArticle',
}

export default apiConfig