import { createStore } from 'vuex'

import { rootUrl } from '@/lib/config'

import { ArticleTypeData } from '@/lib/interfase'
import { getNavData } from '@/api/apis'
import { getFooterConfig } from '@/api/apis'

export default createStore({
  state: {
    rootUrl: rootUrl,
    articleTypes: null,
    activeArticleType: -1,
    pageConfig: {
      address: null,
      links: null,
      wechatImg: null,
      beiAn: null,
      licence: null,

      mainTitle: null,
      mainBanner: null,
    }
  },
  getters: {
    showInIndexArticleTypes(state){
      if(state.articleTypes === null) return null;

      const retArray: ArticleTypeData[] = [];

      for (let i = 0; i < (state.articleTypes as ArticleTypeData[]).length; i++) {
        const item = state.articleTypes[i] as ArticleTypeData
        if(item.isShow){
          retArray.push(item)
        }

        if(item.children != null){
          for (let j = 0; j < item.children.length; j++) {
            const cItem = item.children[j]
            if(cItem.isShow) retArray.push(cItem)
          }
        }
      }

      return retArray
    }
  },
  mutations: {
    updateArticleTypes(state, articleTypes) {
      state.articleTypes = articleTypes
    },
    updateActiveArticleType(state, id){
      state.activeArticleType = id
      // console.log('[UpdateActive]', id)
    },
    updatePageConfig(state, data){
      state.pageConfig.address = data.address
      state.pageConfig.links = data.links
      state.pageConfig.wechatImg = data.weChat
      state.pageConfig.beiAn = data.beiAn
      state.pageConfig.licence = data.licence

      state.pageConfig.mainTitle = data.mainText
      state.pageConfig.mainBanner = data.mainImage
    }
  },
  actions: {
    updateArticleTypes(context) {
      return new Promise((resolve, reject) => {
        if (context.state.articleTypes !== null) {
          resolve(context.state.articleTypes)
        }

        getNavData()
          .then(res => {
            // console.log('[UpdateArticleTypes]', res.data.data)

            if (res != null && res.data != null && res.data.data != null) {
              context.commit('updateArticleTypes', res.data.data)
              resolve(res.data.data)
            }
          })
          .catch(err => {
            console.log('[UpdateArticleTypes Fail]', err)
            reject(err)
          })

      })
    },

    updatePageConfig(context){
      // console.log('[UpdatePageConfig]', )
      return new Promise((resolve, reject) => {
        getFooterConfig()
        .then(res => {
          // console.log('[PageConfig]', res.data)
          context.commit('updatePageConfig', res.data.data)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    }
  },
  modules: {
  }
})
