import axios from 'axios'
import { useStore } from 'vuex'

import { baseURL } from '@/lib/config'

const store = useStore();
const server = axios.create({
    baseURL: baseURL,
    timeout: 5000
})

export default server