import server from '@/utils/request'
import apiConfig from '@/utils/apiConfig'

/**
 * 根据id获取文章内容
 * @param id 文章的id
 * @returns 
 */
export function getArticleById(id: number){
    return server({
        url: apiConfig.getArticleById,
        method: 'post',
        params: {
            id: id
        }
    })
}

/**
 * 获取文章列表
 * @param id 文章类别id
 * @param page 当前页码
 * @param pageCount 每页显示个数 默认 20
 * @returns 
 */
export function getArticleList(id: number, page = 1, pageCount = 20) {
    return server({
        url: apiConfig.getArticleListByType,
        method: 'post',
        params: {
            articleType: id,
            pageNum: page,
            pageCount: pageCount
        }
    })
}

/**
 * 获取导航栏
 */
export function getNavData() {
    return server({
        url: apiConfig.getAllArticleType,
        method: 'post',
    })
}

/**
 * 获取页脚设置
 */
export function getFooterConfig() {
    return server({
        url: apiConfig.getConfigSetting,
        method: 'post'
    })
}

/**
 * 获取最新内容
 * @returns 
 */
export function getNewArticle(num: number) {
    return server({
        url: apiConfig.getNewArticle,
        method: 'post',
        params: {
            num: num
        }
    })
}