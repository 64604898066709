export function getQueryParam(key: string): string | null{
    // (^|&)vd_source=([^&]*)(&|$)
    const reg = new RegExp('(^|&)' + key + "=([^&]*)(&|$)", "i" )
    const splitData = window.location.hash.split('?')

    if(splitData.length < 2){
        return null
    }

    const r = splitData[1].match(reg)
    if(r != null) return decodeURI(r[2]);
    // if(r != null) return decodeURIComponent(r[2]);
    return null
}